//frontend\src\components\Users\UserList.js

import React, { useContext, useState, useEffect } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { api } from '../../api';
import UserEdit from './UserEdit';
import UserCreate from './UserCreate';
import './UserList.css'; // Подключаем CSS файл для стилей

const UserList = () => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]); // Состояние для отфильтрованных пользователей
  const [selectedUser, setSelectedUser] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false); // Состояние для модального окна
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false); // Состояние для модального окна создания пользователя
  const [searchTerm, setSearchTerm] = useState(''); // Состояние для общего фильтра
  const { auth, logout, setAuthToken } = useContext(AuthContext);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        if (auth && auth.token) {
          setAuthToken(auth.token);
          const { data } = await api.get('/users');
          setUsers(data);
          setFilteredUsers(data); // Изначально показываем всех пользователей
        } else {
          console.warn('No auth token available');
        }
      } catch (error) {
        alert('Failed to fetch users');
        console.log(error);
      }
    };

    fetchUsers();
  }, [auth, setAuthToken]);

  // Фильтрация пользователей по любому из столбцов
  useEffect(() => {
    setFilteredUsers(
      users.filter((user) => {
        const term = searchTerm.toLowerCase();
        return (
          user.name.toLowerCase().includes(term) ||
          user.email.toLowerCase().includes(term) ||
          user.role.toLowerCase().includes(term) ||
          (user.department && user.department.toLowerCase().includes(term))
        );
      })
    );
  }, [searchTerm, users]);

  const handleEdit = (user) => {
    setSelectedUser(user);
    setIsModalOpen(true); // Открываем модальное окно для редактирования
  };

  const handleCreateUser = () => {
    setIsCreateModalOpen(true); // Открываем модальное окно для создания пользователя
  };

  const handleCloseModal = () => {
    setIsModalOpen(false); // Закрываем модальное окно
    setSelectedUser(null); // Сбрасываем выбранного пользователя
  };

  const handleCloseCreateModal = () => {
    setIsCreateModalOpen(false); // Закрываем модальное окно создания пользователя
  };

  const handleDelete = async (userId) => {
    try {
      await api.delete(`/users/${userId}`);
      setUsers(users.filter((user) => user._id !== userId));
      alert('User deleted successfully!');
    } catch (error) {
      alert('Failed to delete user');
    }
  };

  return (
    <div className="user-list-container">
      <div className="user-list-header">
        <h2>Users</h2>
        <button onClick={logout}>Logout</button>
        <button onClick={() => window.history.back()}>Back</button>
      </div>
      <div className="user-create-button">
        <button onClick={handleCreateUser}>Создать пользователя</button>
      </div>
      <div className="search-filter">
        <label htmlFor="searchTerm">Search:</label>
        <input
          id="searchTerm"
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search by Name, Email, Role, Department"
        />
      </div>
      <div className="user-table">
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Role</th>
              <th>Department</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {filteredUsers.map((user) => (
              <tr key={user._id}>
                <td>{user.name}</td>
                <td>{user.email}</td>
                <td>{user.role}</td>
                <td>{user.department || 'N/A'}</td>
                <td>
                  <button onClick={() => handleEdit(user)}>Edit</button>
                  <button onClick={() => handleDelete(user._id)}>Delete</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={handleCloseModal}>&times;</span>
            <UserEdit user={selectedUser} closeModal={handleCloseModal} />
          </div>
        </div>
      )}
      {isCreateModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={handleCloseCreateModal}>&times;</span>
            <UserCreate setUsers={setUsers} closeModal={handleCloseCreateModal} />
          </div>
        </div>
      )}
    </div>
  );
};

export default UserList;
