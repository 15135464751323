// frontend/src/components/MainPage.js

import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import DocumentList from './Documents/DocumentList';
import DocumentCreate from './Documents/DocumentCreate';
import { api } from '../api';

const MainPage = () => {
  const { auth, logout, setAuthToken } = useContext(AuthContext);
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [documents, setDocuments] = useState([]);

  // Функция для сортировки документов по дате в порядке убывания
  const sortDocumentsByDate = (docs) => {
    return docs.sort((a, b) => new Date(b.date) - new Date(a.date));
  };

  // Загружаем документы при монтировании компонента
  useEffect(() => {
    if (auth && auth.token) {
      setAuthToken(auth.token);
      const fetchDocuments = async () => {
        try {
          const response = await api.get('/documents');
          const sortedDocuments = sortDocumentsByDate(response.data);
          setDocuments(sortedDocuments);
        } catch (error) {
          console.error('Не удалось загрузить документы', error);
        }
      };
      fetchDocuments();
    }
  }, [auth, setAuthToken]);

  // Обработчик нажатия на кнопку для перехода на Dashboard
  const handleGoToDashboard = () => {
    navigate('/dashboard');
  };

  // Обработчик нажатия на кнопку для перехода на UserList
  const handleGoToUserList = () => {
    navigate('/users');
  };

  // Обработчик для открытия модального окна
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  // Обработчик для закрытия модального окна
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  // Функция для добавления нового документа в список
  const addNewDocument = (newDocument) => {
    setDocuments((prevDocuments) => sortDocumentsByDate([newDocument, ...prevDocuments]));
  };

  // Функция для обновления документа в списке после редактирования
  const updateDocument = (updatedDocument) => {
    setDocuments((prevDocuments) =>
      sortDocumentsByDate(
        prevDocuments.map((doc) =>
          doc._id === updatedDocument._id ? updatedDocument : doc
        )
      )
    );
  };

  return (
    <div>
      <h1 style={{ textAlign: 'center' }}>"Metrics To Insights"</h1>
      <h3 style={{ textAlign: 'center' }}>"Идея сервиса "Metrics To Insights" или "Метрики в Инсайты" заключается в преобразовании данных, полученных из метрик, в полезные выводы или инсайты для дальнейшего анализа или принятия решений."</h3>
      <table>
        <tbody>
          <tr>
            <td>
              {/* Кнопка для перехода на страницу "UserList" */}
              <div className="navigation-button">
                <button onClick={handleGoToUserList}>Go to User List</button>
              </div>
            </td>
            <td>
              {/* Добавляем кнопку для перехода на страницу "Dashboard" */}
              <div className="navigation-button">
                <button onClick={handleGoToDashboard}>Go to Dashboard</button>
              </div>
            </td>
            <td><button onClick={logout}>Logout</button></td>
          </tr>
        </tbody>
      </table>
      <div className="document-list-container">
        <DocumentList
          documents={documents}
          updateDocument={updateDocument}
          handleOpenModal={handleOpenModal}
          handleCloseModal={handleCloseModal}
          isModalOpen={isModalOpen}
          addNewDocument={addNewDocument}
        />
        {/* Модальное окно с компонентом DocumentCreate */}
        {isModalOpen && (
          <div className="modal">
            <div className="modal-content">
              <button onClick={handleCloseModal} className="close-modal-button">
                Закрыть
              </button>
              <DocumentCreate handleCloseModal={handleCloseModal} currentUser={auth} addNewDocument={addNewDocument} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MainPage;
