// frontend/src/components/Documents/DocumentCreate.js

import React, { useState, useEffect } from 'react';
import { api } from '../../api'; // Импортируем модуль api
import { templates } from '../Templates/DocumentTemplates';

let templateOptions = [];

const stars = [
  'Support_C1', 'Support_C2', 'Support_C3', 'Support_C4', 'Support_C5', 'Support_C6', 'Support_C7', 'Support_C8', 'Support_C9', 
  'Support_C10', 'Support_C11', 'Support_C12', 'Support_C13', 'Support_C14'
];

const DocumentCreate = ({ handleCloseModal, currentUser, addNewDocument }) => {
  // Определяем объект с опциями шаблонов по депортаменту пользователя
  useEffect(() => {
    if (!currentUser) {
      return;
    }

    if (stars.includes(currentUser.department)) {
      templateOptions = [{ value: 'Support_TL', label: 'Support_TL' }];
    } else if (templates[currentUser.department]) {
      templateOptions = [{ value: currentUser.department, label: currentUser.department }];
    } else {
      templateOptions = [
        { value: 'KM', label: 'KM' },
        { value: 'KAM', label: 'KAM' },
        { value: 'Development', label: 'Разработка' },
        { value: 'SalesMarketing', label: 'Продажи.Маркетинг' },
        { value: 'SalesChains', label: 'Продажи.Сети' },
        { value: 'Procurement', label: 'Закупка' },
        { value: 'Finance', label: 'Финансы' },
        { value: 'Support_All', label: 'Саппорт (Общий)' },
        { value: 'Support_TL', label: 'Саппорт (Звезды)' },
        { value: 'Support_Night', label: 'Саппорт (Ночь)' },
        { value: 'Support_CTO', label: 'Саппорт (ЦТО)' },
        { value: 'Support_Field', label: 'Саппорт (Выезды)' },
        { value: 'HR', label: 'HR' },
        { value: 'Pre_Sales', label: 'Предпродажи' }
      ];
    }
  }, [currentUser]);

  const [template, setTemplate] = useState(templateOptions[0]?.value || '');
  const [formData, setFormData] = useState({
    templateName: templateOptions[0]?.value || '',
    title: '',
    description: '',
    author: currentUser || '',
    date: '',
    quantity: 0
  });

  // Устанавливаем автора при монтировании компонента
  useEffect(() => {
    if (currentUser) {
      setFormData(prevFormData => ({
        ...prevFormData,
        author: currentUser
      }));
    }
  }, [currentUser]);

  // Обрабатывает смену шаблона
  const handleTemplateChange = (e) => {
    const selectedTemplate = e.target.value;
    // Обновляем текущий шаблон
    setTemplate(selectedTemplate);
    setFormData(prevFormData => ({
      ...prevFormData,
      templateName: selectedTemplate,
      ...templates[selectedTemplate]?.reduce((acc, field) => {
        acc[field.name] = ''; // Инициализация каждого поля пустой строкой
        return acc;
      }, {}),
      author: currentUser || '',
      date: '',
      quantity: 0
    }));
  };

  // Обрабатывает изменение данных формы
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: value,
      author: name !== 'author' ? currentUser : prevFormData.author // Если изменяется любое поле кроме "author", устанавливаем значение поля "author" из currentUser
    }));
  };

  // Обрабатывает отправку формы
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await api.post('/documents', formData);
      const newDocument = response.data;

      addNewDocument(newDocument);
      handleCloseModal();
    } catch (error) {
      alert('Failed to create document');
    }
  };

  return (
    <div>
      <h2>Создание Документа</h2>
      <form onSubmit={handleSubmit}>
        <table>
          <tbody>
            {/* Выпадающий список для выбора шаблона */}
            <tr>
              <td><label>Шаблон формы:</label></td>
              <td>
                <select value={template || ''} onChange={handleTemplateChange}>
                  {templateOptions.map(option => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </td>
            </tr>

            {/* Динамическое отображение полей формы в зависимости от выбранного шаблона */}
            {templates[template]?.map((field) => (
              <tr key={field.name}>
                <td><label>{field.label}:</label></td>
                <td>
                  {field.type === 'text' && (
                    <input
                      type="text"
                      id={field.name}
                      name={field.name}
                      value={formData[field.name] || ''} // Проверка на undefined
                      onChange={handleChange}
                      required={field.required}
                      readOnly={field.readOnly || false}
                    />
                  )}

                  {field.type === 'textarea' && (
                    <textarea
                      id={field.name}
                      name={field.name}
                      value={formData[field.name] || ''} // Проверка на undefined
                      onChange={handleChange}
                      required={field.required}
                      readOnly={field.readOnly || false}
                    />
                  )}

                  {field.type === 'number' && (
                    <input
                      type="number"
                      id={field.name}
                      name={field.name}
                      value={formData[field.name] || ''} // Проверка на undefined
                      onChange={handleChange}
                      required={field.required}
                      readOnly={field.readOnly || false}
                    />
                  )}

                  {field.type === 'date' && (
                    <input
                      type="date"
                      id={field.name}
                      name={field.name}
                      value={formData[field.name] || ''} // Проверка на undefined
                      onChange={handleChange}
                      required={field.required}
                      readOnly={field.readOnly || false}
                    />
                  )}

                  {field.type === 'select' && (
                    <select
                      id={field.name}
                      name={field.name}
                      value={formData[field.name] || ''} // Проверка на undefined
                      onChange={handleChange}
                      required={field.required}
                      disabled={field.readOnly || false}
                    >
                      {field.options.map((option, index) => (
                        <option key={index} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  )}

                  {field.type === 'checkbox' && (
                    <input
                      type="checkbox"
                      id={field.name}
                      name={field.name}
                      checked={!!formData[field.name]} // Проверка на undefined
                      onChange={handleChange}
                      required={field.required}
                      disabled={field.readOnly || false}
                    />
                  )}

                  {field.type === 'radio' && (
                    <input
                      type="radio"
                      id={field.name}
                      name={field.name}
                      value={field.value}
                      checked={formData[field.name] === field.value}
                      onChange={handleChange}
                      required={field.required}
                      disabled={field.readOnly || false}
                    />
                  )}

                  {field.type === 'file' && (
                    <input
                      type="file"
                      id={field.name}
                      name={field.name}
                      onChange={handleChange}
                      required={field.required}
                      disabled={field.readOnly || false}
                    />
                  )}

                  {field.type === 'email' && (
                    <input
                      type="email"
                      id={field.name}
                      name={field.name}
                      value={formData[field.name] || ''} // Проверка на undefined
                      onChange={handleChange}
                      required={field.required}
                      readOnly={field.readOnly || false}
                    />
                  )}

                  {field.type === 'password' && (
                    <input
                      type="password"
                      id={field.name}
                      name={field.name}
                      value={formData[field.name] || ''} // Проверка на undefined
                      onChange={handleChange}
                      required={field.required}
                      readOnly={field.readOnly || false}
                    />
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <br />
        <button type="submit">Сохранить</button>
      </form>
    </div>
  );
};

export default DocumentCreate;
