// frontend/src/context/AuthContext.js

import React, { createContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { api, setAuthToken  } from '../api';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(null);
  const navigate = useNavigate(); // Обновленный импорт

  // Загрузка состояния из локального хранилища при монтировании компонента
  useEffect(() => {
    const storedAuth = localStorage.getItem('auth');
    if (storedAuth) {
      setAuth(JSON.parse(storedAuth));
    }
  }, []);

  // Сохранение состояния в локальное хранилище при изменении состояния
  useEffect(() => {
    if (auth) {
      localStorage.setItem('auth', JSON.stringify(auth));
    } else {
      localStorage.removeItem('auth');
    }
  }, [auth]);

  const login = async (email, password) => {
    try {
      const { data } = await api.post('/auth/login', { email, password });
//      console.log(data);
      setAuth(data);
      setAuthToken(data.token); // Устанавливаем токен в api.js
//      navigate('/DocumentList'); // Использование navigate вместо history
      navigate('/main'); // Использование navigate вместо history
    } catch (error) {
      alert('Login failed');
    }
  };

  const logout = () => {
    setAuthToken(null); // Очищаем токен в api.js
    setAuth(null);
    navigate('/'); // Использование navigate вместо history
  };

  return (
    <AuthContext.Provider value={{ auth, login, logout, setAuthToken }}>
      {children}
    </AuthContext.Provider>
  );
};
