// frontend/src/components/Users/UserEdit.js

import React, { useState } from 'react';
import { api } from '../../api';

// Объект с опциями шаблонов
const departmentOptions = [
  { value: 'KM', label: 'KM' },
  { value: 'KAM', label: 'KAM' },
  { value: 'Development', label: 'Development' },
  { value: 'SalesMarketing', label: 'SalesMarketing' },
  { value: 'SalesChains', label: 'SalesChains' },
  { value: 'Procurement', label: 'Procurement' },
  { value: 'Finance', label: 'Finance' },
  { value: 'Support_All', label: 'Support_All' },
  { value: 'Support_C1', label: 'Support_C1' },
  { value: 'Support_C2', label: 'Support_C2' },
  { value: 'Support_C3', label: 'Support_C3' },
  { value: 'Support_C4', label: 'Support_C4' },
  { value: 'Support_C5', label: 'Support_C5' },
  { value: 'Support_C6', label: 'Support_C6' },
  { value: 'Support_C7', label: 'Support_C7' },
  { value: 'Support_C8', label: 'Support_C8' },
  { value: 'Support_C9', label: 'Support_C9' },
  { value: 'Support_C10', label: 'Support_C10' },
  { value: 'Support_C11', label: 'Support_C11' },
  { value: 'Support_C12', label: 'Support_C12' },
  { value: 'Support_C13', label: 'Support_C13' },
  { value: 'Support_C14', label: 'Support_C14' },
  { value: 'Support_Night', label: 'Support_Night' },
  { value: 'Support_CTO', label: 'Support_CTO' },
  { value: 'Support_Field', label: 'Support_Field' },
  { value: 'HR', label: 'HR' },
  { value: 'Pre_Sales', label: 'Pre_Sales' },
  { value: 'IT', label: 'IT' },
  // Добавьте другие опции для других шаблонов
];

// Объект с опциями ролей
const roleOptions = [
  { value: 'Administrator', label: 'Administrator' },
  { value: 'Manager', label: 'Manager' },
  { value: 'User', label: 'User' },
  // Добавьте другие опции для ролей
];

const UserEdit = ({ user }) => {
  const [name, setName] = useState(user.name);
  const [password, setPassword] = useState(user.password);
  const [email, setEmail] = useState(user.email);
  const [role, setRole] = useState(user.role);
  const [department, setDepartment] = useState(user.department);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await api.put(`/users/${user._id}`, { name, email, password, role, department });
      //      alert('User updated successfully!');
    } catch (error) {
      alert('Failed to update user');
    }
  };

  return (
    <div>
      <h3>Edit User</h3>
      <form onSubmit={handleSubmit}>
        <table>
          <tbody>
            <tr>
              <td><label htmlFor="name">Name:</label></td>
              <td><input
                id="name"
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              /></td>
            </tr>
            <tr>
              <td><label htmlFor="email">Email:</label></td>
              <td><input
                id="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              /></td>
            </tr>
            <tr>
              <td><label htmlFor="password">Password:</label></td>
              <td><input
                id="password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              /></td>
            </tr>
            <tr>
              <td><label htmlFor="role">Role:</label></td>
              <td>
                <select
                  id="role"
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                  required
                >
                  {roleOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </td>
            </tr>
            <tr>
              <td><label htmlFor="department">Department:</label></td>
              <td>
                <select
                  id="department"
                  value={department}
                  onChange={(e) => setDepartment(e.target.value)}
                  required
                >
                  {departmentOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </td>
            </tr>
            <tr>
              <td colSpan="2"><button type="submit">Save</button></td>
            </tr>
          </tbody>
        </table>
      </form>
    </div>
  );
};

export default UserEdit;
