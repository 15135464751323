// frontend/src/components/Documents/DocumentView.js

import React from 'react';
import { templates } from '../Templates/DocumentTemplates'; // Импортируем шаблоны для отображения меток

const DocumentView = ({ document }) => {
  // Находим шаблон на основе названия шаблона в документе
  const template = templates[document.templateName] || [];

  // Функция для отображения значений, в том числе объектов
  const renderFieldValue = (value) => {
    if (typeof value === 'object' && value !== null) {
      // Если значение - объект, рендерим его свойства в виде списка
      return (
        <ul>
          {Object.entries(value).map(([key, val]) => (
            <li key={key}>
              <strong>{key}:</strong> {val}
            </li>
          ))}
        </ul>
      );
    }
    // Если значение не объект, просто возвращаем его
    return value;
  };

  return (
    <div>
      <h2>View Document</h2>
      <table>
        <tbody>
          {template.map((field) => (
            <tr key={field.name}>
              <th>{field.label}:</th>
              <td>{renderFieldValue(document[field.name])}</td>
            </tr>
          ))}
          {/* Отображаем название шаблона */}
          <tr>
            <th>Template Name:</th>
            <td>{document.templateName}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default DocumentView;
