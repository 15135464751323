// frontend/src/components/Templates/DocumentTemplates.js

export const templates = {
  KM: [
    { name: 'date', label: 'Дата', type: 'date', required: true },
    { name: 'totalPoints', label: 'Точек всего', type: 'number', required: true },
    { name: 'paidPoints', label: 'Точек оплачено', type: 'number', required: true },
    { name: 'implementationPoints', label: 'Точек на внедрении', type: 'number', required: true },
    { name: 'promoPoints', label: 'Точек на Промо', type: 'number', required: true },
    { name: 'marginalIncomeRegulated', label: 'Маржинальный приход регул', type: 'number', required: true },
    { name: 'marginalIncomeNonRegulated', label: 'Маржинальный приход нерегул', type: 'number', required: true },
    { name: 'currentClientReceivables', label: 'Дебиторка по текущим клиентам', type: 'number', required: true },
    { name: 'advancesOver6Months', label: 'Авансы более 6 месяцев', type: 'number', required: true },
    { name: 'requestsReceived', label: 'Поступило заявок', type: 'number', required: true },
    { name: 'requestsResolved', label: 'Решено заявок', type: 'number', required: true },
    { name: 'requestsStack', label: 'Стек заявок', type: 'number', required: true },
    { name: 'requestsOver24Hours', label: 'Заявок больше 24 часов', type: 'number', required: true },
    { name: 'author', label: 'Автор', type: 'text', required: true, readOnly: true },
  ],
  KAM: [
    { name: 'date', label: 'Дата', type: 'date', required: true },
    { name: 'newPointsCurrentClients', label: 'Новые точки текущ. клиентов', type: 'number', required: true },
    { name: 'newPointsAttracted', label: 'Новые точки привлеченные', type: 'number', required: true },
    { name: 'marginalIncomeRegulated', label: 'Маржинальный приход регул', type: 'number', required: true },
    { name: 'marginalIncomeNonRegulated', label: 'Маржинальный приход нерегул', type: 'number', required: true },
    { name: 'kevConductedActive', label: 'КЭВ проведено в актив', type: 'number', required: true },
    { name: 'kevConductedMarketing', label: 'КЭВ проведено от маркетинга', type: 'number', required: true },
    { name: 'requestsReceived', label: 'Поступило заявок', type: 'number', required: true },
    { name: 'requestsResolved', label: 'Решено заявок', type: 'number', required: true },
    { name: 'requestsStack', label: 'Стек заявок', type: 'number', required: true },
    { name: 'requestsOver24Hours', label: 'Заявок больше 24 часов', type: 'number', required: true },
    { name: 'author', label: 'Автор', type: 'text', required: true, readOnly: true },
  ],
  SalesMarketing: [
    { name: 'date', label: 'Дата', type: 'date', required: true },
    { name: 'newPointsMarketing', label: 'Новые точки Маркетинг', type: 'number', required: true },
    { name: 'newPointsActiveSearch', label: 'Новые точки Активный поиск', type: 'number', required: true },
    { name: 'totalPartners', label: 'Всего партнеров', type: 'number', required: true },
    { name: 'marginalIncomeRegulated', label: 'Маржинальный приход регул', type: 'number', required: true },
    { name: 'marginalIncomeNonRegulated', label: 'Маржинальный приход нерегул', type: 'number', required: true },
    { name: 'kevZoomConducted', label: 'Пров. КЭВ зум', type: 'number', required: true },
    { name: 'kevMeetingConducted', label: 'Пров. КЭВ встреча', type: 'number', required: true },
    { name: 'kevPhoneConducted', label: 'Пров. КЭВ телефон', type: 'number', required: true },
    { name: 'kevZoomScheduled', label: 'Назн. КЭВ Вход зум', type: 'number', required: true },
    { name: 'kevMeetingScheduled', label: 'Назн. КЭВ Вход встреча', type: 'number', required: true },
    { name: 'kevPhoneScheduled', label: 'Назн. КЭВ Вход телефон', type: 'number', required: true },
    { name: 'docsInBoxNewPoints', label: 'DocsInBox Новые точки', type: 'number', required: true },
    { name: 'docsInBoxPayment', label: 'DocsInBox Оплата (тыс. руб.)', type: 'number', required: true },
    { name: 'docsInBoxSalesNewRegular', label: 'DocsInBox Сумма продаж нов. регулярки (тыс. руб)', type: 'number', required: true },
    { name: 'pointerNew', label: 'Pointer новые', type: 'number', required: true },
    { name: 'pointerCurrent', label: 'Pointer текущие', type: 'number', required: true },
    { name: 'getMeBackNew', label: 'GetMeBack новые', type: 'number', required: true },
    { name: 'getMeBackCurrent', label: 'GetMeBack текущие', type: 'number', required: true },
    { name: 'revvyNew', label: 'Revvy новые', type: 'number', required: true },
    { name: 'revvyCurrent', label: 'Revvy текущие', type: 'number', required: true },
    { name: 'premiumBonusNew', label: 'Premium Bonus новые', type: 'number', required: true },
    { name: 'premiumBonusCurrent', label: 'Premium Bonus текущие', type: 'number', required: true },
    { name: 'smartomatoNew', label: 'Smartomato новые', type: 'number', required: true },
    { name: 'smartomatoCurrent', label: 'Smartomato текущие', type: 'number', required: true },
    { name: 'swipNew', label: 'SWIP новые', type: 'number', required: true },
    { name: 'swipCurrent', label: 'SWIP текущие', type: 'number', required: true },
    { name: 'foodPicassoNew', label: 'ФудПикассо новые', type: 'number', required: true },
    { name: 'foodPicassoCurrent', label: 'ФудПикассо текущие', type: 'number', required: true },
    { name: 'primeHillNew', label: 'ПраймХилл новые', type: 'number', required: true },
    { name: 'primeHillCurrent', label: 'ПраймХилл текущие', type: 'number', required: true },
    { name: 'author', label: 'Автор', type: 'text', required: true, readOnly: true },
  ],
  SalesChains: [
    { name: 'date', label: 'Дата', type: 'date', required: true },
    { name: 'newPointsChains5Plus', label: 'Новые Точки (Сети 5+)', type: 'number', required: true },
    { name: 'newPointsSingleAndChains5Minus', label: 'Новые точки (Одиночные и сети 5-)', type: 'number', required: true },
    { name: 'marginalIncomeRegulated', label: 'Маржинальный приход регул', type: 'number', required: true },
    { name: 'marginalIncomeNonRegulated', label: 'Маржинальный приход нерегул', type: 'number', required: true },
    { name: 'newChains5To9', label: 'Новые сети 5-9 точек', type: 'number', required: true },
    { name: 'newChains10To19', label: 'Новые сети 10-19 точек', type: 'number', required: true },
    { name: 'newChains20Plus', label: 'Новые сети 20+ точек', type: 'number', required: true },
    { name: 'kevConducted5Points', label: 'Пров. КЭВ 5- точек', type: 'number', required: true },
    { name: 'kevConducted5PlusPoints', label: 'Пров. КЭВ 5+ точек', type: 'number', required: true },
    { name: 'kevConducted10PlusPoints', label: 'Пров. КЭВ 10+ точек', type: 'number', required: true },
    { name: 'kevConducted20PlusPoints', label: 'Пров. КЭВ 20+ точек', type: 'number', required: true },
    { name: 'author', label: 'Автор', type: 'text', required: true, readOnly: true },
  ],
  Development: [
    { name: 'date', label: 'Дата', type: 'date', required: true },
    { name: 'projectsStack', label: 'Стек проектов', type: 'number', required: true },
    { name: 'projectsReceived', label: 'Поступило проектов', type: 'number', required: true },
    { name: 'projectsNotPlanned', label: 'Не спланировано проектов', type: 'number', required: true },
    { name: 'projectsInProgress', label: 'В работе проектов', type: 'number', required: true },
    { name: 'projectsClosed', label: 'Закрыто проектов', type: 'number', required: true },
    { name: 'paymentsReceived', label: 'Оплат поступило', type: 'number', required: true },
    { name: 'author', label: 'Автор', type: 'text', required: true, readOnly: true },
  ],
  Procurement: [
    { name: 'date', label: 'Дата', type: 'date', required: true },
    { name: 'substitutionsOnStock', label: 'Подмен на складе', type: 'number', required: true },
    { name: 'substitutionsOnClientsTotal', label: 'Подмен у клиентов всего', type: 'number', required: true },
    { name: 'substitutionsOnClientsOver1Month', label: 'Подмен у клиентов > 1 мес', type: 'number', required: true },
    { name: 'substitutionsInstalled', label: 'Подмен поставили', type: 'number', required: true },
    { name: 'substitutionsTaken', label: 'Подмен забрали', type: 'number', required: true },
    { name: 'nonLiquidStock', label: 'Неликвид остаток на складе', type: 'number', required: true },
    { name: 'nonLiquidSold', label: 'Неликвид реализовано, руб.', type: 'number', required: true },
    { name: 'plannedHiring', label: 'Плановый найм сотрудников', type: 'number', required: true },
    { name: 'sla', label: 'SLA', type: 'number', required: true },
    { name: 'costToTurnoverPercentage', label: '% стоимости к обороту д/с', type: 'number', required: true },
    { name: 'author', label: 'Автор', type: 'text', required: true, readOnly: true },
  ],
  Finance: [
    { name: 'date', label: 'Дата', type: 'date', required: true },
    { name: 'projectsClosedNotRealized', label: 'Проекты закрыты и не реализованы', type: 'number', required: true },
    { name: 'projectsRealized', label: 'Реализовано проектов', type: 'number', required: true },
    { name: 'advancesOver6Months', label: 'Сумма авансов (>6 мес.)', type: 'number', required: true },
    { name: 'receivablesFromDepartedClients', label: 'Дебиторка по ушедшим клиентам', type: 'number', required: true },
    { name: 'marginalIncomeForecastRegular', label: 'Маржа прогнозная факт, регулярка', type: 'number', required: true },
    { name: 'marginalIncomeForecastNonRegular', label: 'Маржа прогнозная факт, нерегулярка', type: 'number', required: true },
    { name: 'author', label: 'Автор', type: 'text', required: true, readOnly: true },
  ],
  Support_All: [
    { name: 'date', label: 'Дата', type: 'date', required: true },
    { name: 'clientRequestsStack', label: 'Стэк клиентских заявок', type: 'number', required: true },
    { name: 'internalRequestsStack', label: 'Стэк внутренних заявок', type: 'number', required: true },
    { name: 'clientRequests24h', label: 'Заявок клиентских 24ч', type: 'number', required: true },
    { name: 'internalRequests24h', label: 'Заявок внутренних 24ч', type: 'number', required: true },
    { name: 'incomingCallsCount', label: 'Количество входящих звонков', type: 'number', required: true },
    { name: 'missedCallsCount', label: 'Количество пропущенных', type: 'number', required: true },
    { name: 'unansweredCallsCount', label: 'Количество не отзвоненных', type: 'number', required: true },
    { name: 'uncompletedCallsCount', label: 'Количество недозвонов', type: 'number', required: true },
    { name: 'author', label: 'Автор', type: 'text', required: true, readOnly: true },
  ],
  Support_TL: [
    { name: 'date', label: 'Дата', type: 'date', required: true },
    { name: 'requestsReceived', label: 'Поступило заявок', type: 'number', required: true },
    { name: 'requestsResolved', label: 'Решено заявок', type: 'number', required: true },
    { name: 'problemCount', label: 'Кол-во проблем', type: 'number', required: true },
    { name: 'overduerequests', label: 'Просрочено заявок', type: 'number', required: true },
    { name: 'requestsStack', label: 'Стек заявок', type: 'number', required: true },
    { name: 'projectsReceived', label: 'Поступило проектов', type: 'number', required: true },
    { name: 'acceptancesReceived', label: 'Поступило приемок', type: 'number', required: true },
    { name: 'projectsClosed', label: 'Закрыто проектов', type: 'number', required: true },
    { name: 'acceptancesClosed', label: 'Закрыто приемок', type: 'number', required: true },
    { name: 'projectsStack', label: 'Стэк проектов', type: 'number', required: true },
    { name: 'acceptancesStack', label: 'Стек приемок', type: 'number', required: true },
    { name: 'overdueKTs', label: 'Просроченные КТ', type: 'number', required: true },
    { name: 'uncarriedOutInstallationSessions', label: 'Не проведенные установочные сессии', type: 'number', required: true },
    { name: 'requestsOver24Hours', label: 'Заявок больше 24 часов', type: 'number', required: true },
    { name: 'internalEscalations', label: 'Эскалации внутренние', type: 'number', required: true },
    { name: 'clientEscalations', label: 'Эскалации клиентские', type: 'number', required: true },
    { name: 'pointsInService', label: 'Точек на обслуживании', type: 'number', required: true },
    { name: 'pointsLostToCompetitors', label: 'Ушло точек к конкурентам', type: 'number', required: true },
    { name: 'staffCount', label: 'Сотрудников', type: 'number', required: true },
    { name: 'author', label: 'Автор', type: 'text', required: true, readOnly: true },
  ],
  Support_Night: [
    { name: 'date', label: 'Дата', type: 'date', required: true },
    { name: 'requestsReceived', label: 'Поступило заявок', type: 'number', required: true },
    { name: 'requestsResolved', label: 'Решено заявок', type: 'number', required: true },
    { name: 'requestsNotCompleted', label: 'Не выполнено заявок', type: 'number', required: true },
    { name: 'requestsTransferredDay', label: 'Передано в день заявок', type: 'number', required: true },
    { name: 'slaWorkStart', label: 'SLA взятия в работу', type: 'number', required: true },
    { name: 'slaResolution', label: 'SLA решения', type: 'number', required: true },
    { name: 'slaOverall', label: 'SLA общий', type: 'number', required: true },
    { name: 'totalCalls', label: 'Всего звонков', type: 'number', required: true },
    { name: 'missedCalls', label: 'Пропущено звонков', type: 'number', required: true },
    { name: 'staffCount', label: 'Сотрудников', type: 'number', required: true },
    { name: 'internalEscalations', label: 'Эскалации внутренние', type: 'number', required: true },
    { name: 'author', label: 'Автор', type: 'text', required: true, readOnly: true },
  ],
  Support_CTO: [
    { name: 'date', label: 'Дата', type: 'date', required: true },
    { name: 'requestsReceived', label: 'Поступило заявок', type: 'number', required: true },
    { name: 'requestsResolved', label: 'Решено заявок', type: 'number', required: true },
    { name: 'requestsStack', label: 'Стек заявок', type: 'number', required: true },
    { name: 'overduerequests', label: 'Просрочено заявок', type: 'number', required: true },
    { name: 'author', label: 'Автор', type: 'text', required: true, readOnly: true },
  ],
  Support_Field: [
    { name: 'date', label: 'Дата', type: 'date', required: true },
    { name: 'requestsReceived', label: 'Поступило заявок', type: 'number', required: true },
    { name: 'requestsResolved', label: 'Решено заявок', type: 'number', required: true },
    { name: 'requestsResolvedByStaff', label: 'Решено заявок сотрудниками', type: 'number', required: true },
    { name: 'requestsResolvedByOutsourcers', label: 'Решено заявок аутсорсерами', type: 'number', required: true },
    { name: 'requestsResolvedWithoutVisit', label: 'Решено заявок без выезда', type: 'number', required: true },
    { name: 'overduerequests', label: 'Просрочено заявок', type: 'number', required: true },
    { name: 'sla', label: 'SLA', type: 'number', required: true },
    { name: 'staffCount', label: 'Сотрудников', type: 'number', required: true },
    { name: 'outsourcersCount', label: 'Аутсорсеров', type: 'number', required: true },
    { name: 'author', label: 'Автор', type: 'text', required: true, readOnly: true },
  ],
  HR: [
    { name: 'date', label: 'Дата', type: 'date', required: true },
    { name: 'readyToStart', label: 'Согласны выйти', type: 'number', required: true },
    { name: 'readyForLastMile', label: 'Из них в Последнюю милю', type: 'number', required: true },
    { name: 'onAdaptationTotal', label: 'На адаптации всего', type: 'number', required: true },
    { name: 'onWeeklyAdaptation', label: 'Из них на еженедельной адаптации', type: 'number', required: true },
    { name: 'firedFromAdaptation', label: 'Уволены из адап-ции', type: 'number', required: true },
    { name: 'author', label: 'Автор', type: 'text', required: true, readOnly: true },
  ],
  Pre_Sales: [
    { name: 'date', label: 'Дата', type: 'date', required: true },
    { name: 'requestsReceivedPP', label: 'Поступило заявок ПП.', type: 'number', required: true },
    { name: 'requestsResolvedPP', label: 'Решено заявок ПП.', type: 'number', required: true },
    { name: 'overduerequestsPP', label: 'Просрочено заявок ПП.', type: 'number', required: true },
    { name: 'requestsStackPP', label: 'Стек заявок ПП.', type: 'number', required: true },
    { name: 'author', label: 'Автор', type: 'text', required: true, readOnly: true },
  ],

  // Добавьте другие шаблоны по необходимости
};



/*
    basic: [
        { name: 'title', label: 'Title', type: 'text', required: true },
        { name: 'description', label: 'Description', type: 'textarea', required: true },
        { name: 'author', label: 'Author', type: 'text', required: true, readOnly: true },// Поле "Author" с атрибутом readOnly
        { name: 'date', label: 'Date', type: 'date', required: true }  // Добавлено поле для даты  
      ],
      advanced: [
        { name: 'title', label: 'Title', type: 'text', required: true },
        { name: 'description', label: 'Description', type: 'textarea', required: true },
        { name: 'extraInfo', label: 'Extra Information', type: 'text', required: false },
        { name: 'quantity', label: 'Quantity', type: 'number', required: true },  // Числовое поле "Quantity"
        { 
          name: 'category',  // Название поля
          label: 'Category', // Метка для поля
          type: 'select',    // Тип поля - выпадающий список
          required: true,    // Делает поле обязательным для заполнения
          options: [         // Массив вариантов выбора
            { value: 'business', label: 'Business' },
            { value: 'personal', label: 'Personal' },
            { value: 'other', label: 'Other' }
          ]
        },
        { name: 'date', label: 'Date', type: 'date', required: true },  // Добавлено поле для даты
        { name: 'author', label: 'Author', type: 'text', required: true, readOnly: true }  // Поле "Author" с атрибутом readOnly
  
      ],
  */