// frontend/src/components/Users/UserCreate.js

import React, { useState } from 'react';
import { api } from '../../api';
import { departmentOptions } from '../Templates/UsersDepartmentOptions';
//import './UserCreate.css';

// Объект с опциями ролей
const roleOptions = [
  { value: 'Administrator', label: 'Administrator' },
  { value: 'Manager', label: 'Manager' },
  { value: 'User', label: 'User' },
  // Добавьте другие опции для ролей
];

const UserCreate = ({ setUsers }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [role, setRole] = useState(roleOptions[0].value);
  const [department, setDepartment] = useState(departmentOptions[0].value);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const { data } = await api.post('/users', { name, email, password, role, department });
      setUsers((prevUsers) => [...prevUsers, data]);
      setName('');
      setEmail('');
      setPassword('');
      setRole(roleOptions[0].value); // Устанавливаем начальное значение для роли
      setDepartment(departmentOptions[0].value); // Сброс к начальному значению
      alert('User created successfully!');
    } catch (error) {
      alert('Failed to create user');
    }
  };

  return (
    <div>
      <h2>Create User</h2>
      <form onSubmit={handleSubmit}>
        <table>
          <tbody>
            <tr>
              <td><label htmlFor="name">Name:</label></td>
              <td><input
                id="name"
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              /></td>
            </tr>
            <tr>
              <td><label htmlFor="email">Email:</label></td>
              <td><input
                id="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              /></td>
            </tr>
            <tr>
              <td><label htmlFor="password">Password:</label></td>
              <td><input
                id="password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              /></td>
            </tr>
            <tr>
              <td><label htmlFor="role">Role:</label></td>
              <td>
                <select
                  id="role"
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                  required
                >
                  {roleOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </td>
            </tr>
            <tr>
              <td><label htmlFor="department">Department:</label></td>
              <td>
                <select
                  id="department"
                  value={department}
                  onChange={(e) => setDepartment(e.target.value)}
                  required
                >
                  {departmentOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </td>
            </tr>
            <tr>
              <td colSpan="2"><button type="submit">Save</button></td>
            </tr>
          </tbody>
        </table>
      </form>
    </div>
  );
};

export default UserCreate;
