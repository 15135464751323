// frontend\src\components\Templates\UsersDepartmentOptions.js


// Объект с опциями шаблонов
export const departmentOptions = [
    { value: 'KM', label: 'KM' },
    { value: 'KAM', label: 'KAM' },
    { value: 'Development', label: 'Development' },
    { value: 'SalesMarketing', label: 'SalesMarketing' },
    { value: 'SalesChains', label: 'SalesChains' },
    { value: 'Procurement', label: 'Procurement' },
    { value: 'Finance', label: 'Finance' },
    { value: 'Support_All', label: 'Support_All' },
    { value: 'Support_C1', label: 'Support_C1' },
    { value: 'Support_C2', label: 'Support_C2' },
    { value: 'Support_C3', label: 'Support_C3' },
    { value: 'Support_C4', label: 'Support_C4' },
    { value: 'Support_C5', label: 'Support_C5' },
    { value: 'Support_C6', label: 'Support_C6' },
    { value: 'Support_C7', label: 'Support_C7' },
    { value: 'Support_C8', label: 'Support_C8' },
    { value: 'Support_C9', label: 'Support_C9' },
    { value: 'Support_C10', label: 'Support_C10' },
    { value: 'Support_C11', label: 'Support_C11' },
    { value: 'Support_C12', label: 'Support_C12' },
    { value: 'Support_C13', label: 'Support_C13' },
    { value: 'Support_C14', label: 'Support_C14' },
    { value: 'Support_Night', label: 'Support_Night' },
    { value: 'Support_CTO', label: 'Support_CTO' },
    { value: 'Support_Field', label: 'Support_Field' },
    { value: 'HR', label: 'HR' },
    { value: 'Pre_Sales', label: 'Pre_Sales' },
    { value: 'IT', label: 'IT' },
    // Добавьте другие опции для других шаблонов
  ];