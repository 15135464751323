// frontend/src/components/Documents/DocumentEdit.js

import React, { useState } from 'react';
import { api } from '../../api';
import { templates } from '../Templates/DocumentTemplates';

const DocumentEdit = ({ document, handleCloseModal, updateDocument }) => {
  // Инициализация состояния формы с данными документа
  const [formData, setFormData] = useState(() => {
    const initialData = {};
    templates[document.templateName].forEach((field) => {
      initialData[field.name] = document[field.name] || '';
    });
    return {
      ...initialData,
      templateName: document.templateName,
    };
  });

  // Обработка изменения полей формы
  const handleChange = (e) => {
    const { name, type, value, checked, files } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : type === 'file' ? files[0] : value,
    });
  };

  // Обработка отправки формы
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Отправка обновленных данных на сервер
      const { data } = await api.put(`/documents/${document._id}`, formData);

      // Обновляем документ в списке через updateDocument
      updateDocument(data);

      // Закрытие модального окна после успешного обновления документа
      handleCloseModal();
    } catch (error) {
      alert('Failed to update document');
    }
  };

  return (
    <div>
      <h2>Изменение Документа</h2>
      <form onSubmit={handleSubmit}>
        <table>
          <tbody>
            {templates[formData.templateName].map((field) => (
              <tr key={field.name}>
                <th>
                  <label htmlFor={field.name}>{field.label}:</label>
                </th>
                <td>
                  {field.type === 'text' && (
                    <input
                      type="text"
                      id={field.name}
                      name={field.name}
                      value={formData[field.name]}
                      onChange={handleChange}
                    />
                  )}
                  {field.type === 'date' && (
                    <input
                      type="date"
                      id={field.name}
                      name={field.name}
                      value={formData[field.name]}
                      onChange={handleChange}
                    />
                  )}
                  
                  {field.type === 'number' && (
                    <input
                      type="number"
                      id={field.name}
                      name={field.name}
                      value={formData[field.name] || ''} // Проверка на undefined
                      onChange={handleChange}
                      required={field.required}
                      readOnly={field.readOnly || false}
                    />
                  )}

                  {/* Добавьте сюда другие типы полей при необходимости */}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <button type="submit">Сохранить</button>
      </form>
    </div>
  );
};

export default DocumentEdit;
