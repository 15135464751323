// frontend/src/components/Dashboard/Dashboard.js

import React, { useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';

const Dashboard = () => {
  const { logout } = useContext(AuthContext);

  return (
    <div>
      <h1>Dashboard</h1>
      <button onClick={logout}>Logout</button>
      <button onClick={() => window.history.back()}>Back</button>

      {/* Встроенный дашборд Metabase */}
      <div style={{ width: '100%', height: '80vh', marginTop: '20px' }}>
        <iframe
          src="http://localhost:3000/public/dashboard/e0430916-ad55-4fd8-bad7-fae5169bc6fc" // замените на ваш URL
          frameBorder="0"
          width="100%"
          height="100%"
          title="Metabase Dashboard"
        />
      </div>
    </div>
  );
};

export default Dashboard;
