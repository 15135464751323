// frontend/src/components/Documents/DocumentList.js

import React, { useState, useContext } from 'react';
import DocumentView from './DocumentView';
import DocumentEdit from './DocumentEdit';
import DocumentCreate from './DocumentCreate'; // Импортируем компонент для создания документа
import { AuthContext } from '../../context/AuthContext';
import { departmentOptions } from '../Templates/UsersDepartmentOptions';
import '../../CSS/DocumentList.css'; // Импортируем стили

const DocumentList = ({ documents, updateDocument, addNewDocument }) => {
  const { auth } = useContext(AuthContext); // Достаем информацию о пользователе из контекста авторизации
  const [filter, setFilter] = useState(''); // Состояние для фильтрации по департаменту
  const [selectedDocument, setSelectedDocument] = useState(null); // Состояние для выбранного документа
  const [isEditModalOpen, setIsEditModalOpen] = useState(false); // Состояние для модального окна редактирования
  const [isViewModalOpen, setIsViewModalOpen] = useState(false); // Состояние для модального окна просмотра
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false); // Состояние для модального окна создания

  // Состояние для пагинации
  const [currentPage, setCurrentPage] = useState(1); // Текущая страница
  const documentsPerPage = 10; // Количество документов на одной странице


  // Обработка нажатия для просмотра документа
  const handleView = (document) => {
    setSelectedDocument(document); // Устанавливаем выбранный документ
    setIsViewModalOpen(true); // Открываем модальное окно просмотра
  };

  // Обработка нажатия для редактирования документа
  const handleEdit = (document) => {
    setSelectedDocument(document); // Устанавливаем документ для редактирования
    setIsEditModalOpen(true); // Открываем модальное окно редактирования
  };

  // Открытие модального окна для создания документа
  const handleOpenCreateModal = () => {
    setIsCreateModalOpen(true); // Открываем модальное окно создания
  };

  // Закрытие всех модальных окон
  const handleCloseModal = () => {
    setIsEditModalOpen(false); // Закрываем окно редактирования
    setIsViewModalOpen(false); // Закрываем окно просмотра
    setIsCreateModalOpen(false); // Закрываем окно создания
  };

  // Проверка роли пользователя: администратор или менеджер
  const isAdminOrManager = auth && (auth.role === 'Administrator' || auth.role === 'Manager');

  // Фильтрация документов по департаменту для администраторов и менеджеров
  const filteredDocuments = documents.filter((document) => {
    if (!auth || !auth.department) return true;
    return isAdminOrManager
      ? filter === '' || document.author.department === filter // Фильтр по департаменту для администраторов и менеджеров
      : document.author.department === auth.department; // Для остальных — показываем документы их департамента
  });

  // Логика для пагинации
  const indexOfLastDocument = currentPage * documentsPerPage;
  const indexOfFirstDocument = indexOfLastDocument - documentsPerPage;
  const currentDocuments = filteredDocuments.slice(indexOfFirstDocument, indexOfLastDocument);
  const totalPages = Math.ceil(filteredDocuments.length / documentsPerPage); // Общее количество страниц

  const paginate = (pageNumber) => setCurrentPage(pageNumber); // Функция для смены страницы

  return (
    <div className="document-list-wrapper">
      <div className="document-list-container">
        <div className="document-list-header">
          <h2>Список документов</h2>

          {/* Кнопка для открытия окна создания документа */}
          <div className="document-create-button">
            <button onClick={handleOpenCreateModal}> + Создать документ</button>
          </div>
        </div>

        {/* Фильтр по департаменту, доступен только для администраторов и менеджеров */}
        {isAdminOrManager && (
          <label>
            Фильтр по Департаменту:
            <select value={filter} onChange={(e) => setFilter(e.target.value)}>
              <option value=''>All</option>
              {departmentOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </label>
        )}


        {/* Отображение списка документов */}
        {currentDocuments.length === 0 ? (
          <p>Документы не найдены.</p>
        ) : (
          <>
            <table>
              <thead>
                <tr>
                  <th>Автор</th>
                  <th>Дата</th>
                  <th>Департамент</th>
                  <th>Действие</th>
                </tr>
              </thead>
              <tbody>
                {currentDocuments.map((document) => (
                  <tr key={document._id}>
                    <td>{document.author.name}</td>
                    <td>{document.date}</td>
                    <td>{document.author.department}</td>
                    <td>
                      <div className="button-group">
                        <button onClick={() => handleView(document)}>Просмотреть</button>
                        <button className="edit-button" onClick={() => handleEdit(document)}>Редактировать</button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            {/* Пагинация */}
            <div className="pagination">
              {[...Array(totalPages)].map((_, i) => (
                <button
                  key={i}
                  className={`page-button ${i + 1 === currentPage ? 'active' : ''}`} // Подсвечиваем текущую страницу
                  onClick={() => paginate(i + 1)}
                >
                  {i + 1}
                </button>
              ))}
            </div>
          </>
        )}

        {/* Модальное окно для создания документа */}
        {isCreateModalOpen && (
          <div className="modal">
            <div className="modal-content">
              <button onClick={handleCloseModal} className="close-modal-button">
                Закрыть
              </button>
              <DocumentCreate handleCloseModal={handleCloseModal} currentUser={auth} addNewDocument={addNewDocument} />
            </div>
          </div>
        )}

        {/* Модальное окно для просмотра документа */}
        {isViewModalOpen && selectedDocument && (
          <div className="modal">
            <div className="modal-content">
              <button onClick={handleCloseModal} className="close-modal-button">
                Закрыть
              </button>
              <DocumentView document={selectedDocument} />
            </div>
          </div>
        )}

        {/* Модальное окно для редактирования документа */}
        {isEditModalOpen && (
          <div className="modal">
            <div className="modal-content">
              <button onClick={handleCloseModal} className="close-modal-button">
                Закрыть
              </button>
              <DocumentEdit
                document={selectedDocument}
                handleCloseModal={handleCloseModal}
                updateDocument={updateDocument} // Функция обновления документа
              />
            </div>
          </div>
        )}
      </div>

      {/* Контейнер для iFrame */}
      <div className="iframe-container">
        <iframe
          src="https://www.example.com" // Замените на нужный URL
          title="Example iFrame"
          width="400"
          height="300"
        />
      </div>
    </div>
  );
};

export default DocumentList;
